import { Controller } from '@hotwired/stimulus';
import { showV2, hideV2 } from '../utils/display';

const DEFAULT_MAX_SELECTION = 2;

const disallowSelection = (checkboxes) =>
  checkboxes
    .filter((checkbox) => !checkbox.checked)
    .forEach((checkbox) => (checkbox.disabled = true));

const allowSelection = (checkboxes) =>
  checkboxes
    .filter((checkbox) => checkbox.disabled && !checkbox.classList.contains('no-sensor'))
    .forEach((checkbox) => (checkbox.disabled = false));

const countChecked = (checkboxes) => {
  return checkboxes.filter((checkbox) => checkbox.checked).length;
};

const sanitizeMaxCount = (input) => {
  return input !== '' ? Number(input) : NaN;
};

const displayedCheckboxes = (checkboxes) =>
  checkboxes.filter(
    (checkbox) => !checkbox.parentElement.classList.contains('is-hidden')
  );

const selectAll = (checkboxes) =>
  checkboxes.filter((checkbox) => (checkbox.checked = true));

const checkAllWeCan = (total, checkboxes) => {
  if (total > 0) {
    selectAll(
      checkboxes.filter((checkbox) => !checkbox.checked && !checkbox.disabled).slice(0, total)    );
  }
};

const filterCheckboxes = (targets) => {
  return displayedCheckboxes(targets).filter((checkbox) => {
    const parentContainer = checkbox.closest('.form-analyse__checkbox');
    return !parentContainer || !parentContainer.classList.contains('is-hidden');
  });
};

const toggleAlertMessage = (show, alertMessageTarget) => {
  show ? showV2(alertMessageTarget) : hideV2(alertMessageTarget);
};

const typeToTargetMap = {
  rooms: 'checkboxRoomsTargets',
  outdoor_zones: 'checkboxOutdoorZonesTargets',
  equipment: 'checkboxEquipmentTargets',
  codes: 'checkboxCodesTargets'
};

const getTargetsByType = (type, targets) => targets[typeToTargetMap[type]];

export default class extends Controller {
  static targets = [
    'checkbox',
    'checkboxRooms',
    'checkboxOutdoorZones',
    'checkboxEquipment',
    'checkboxCodes',
    'selectAllRooms',
    'selectAllOutdoorZones',
    'selectAllEquipment',
    'selectAllCodes',
    'message',
    'alertMessage',
    'submitButton',
  ];

  connect() {
    const sanitizedInput = sanitizeMaxCount(this.data.get('maxCount'));
    this.maxCount = sanitizedInput || DEFAULT_MAX_SELECTION;
  }

  check() {
    const checkboxesList = [...this.checkboxTargets];
    if (this.hasSelectAllRoomsTarget) {
      checkboxesList.push(this.selectAllRoomsTarget);
    }
    if (this.hasSelectAllOutdoorZonesTarget) {
      checkboxesList.push(this.selectAllOutdoorZonesTarget);
    }
    if (this.hasSelectAllEquipmentTarget) {
      checkboxesList.push(this.selectAllEquipmentTarget);
    }

    if (countChecked(this.checkboxTargets) >= this.maxCount) {
      disallowSelection(checkboxesList);
      if (this.hasMessageTarget) showV2(this.messageTarget);
    } else {
      allowSelection(checkboxesList);
      if (this.hasMessageTarget) hideV2(this.messageTarget);
    }
    this.toggleSubmitActionState();
  }

  selectAll(event) {
    const type = event.target.dataset.type;
    const targets = getTargetsByType(type, this);
    const checkboxes = filterCheckboxes(targets);
    const totalCheckable = this.maxCount - countChecked(this.checkboxTargets);
    checkAllWeCan(totalCheckable, checkboxes);
    this.toggleSubmitActionState();
  }

  selectNone(event) {
    const type = event.target.dataset.type;
    const targets = getTargetsByType(type, this);
    displayedCheckboxes(targets).map((checkbox) => (checkbox.checked = false));
    allowSelection(targets);
    this.toggleSubmitActionState();
  }

  toggleSubmitActionState() {    
    const checkboxes = filterCheckboxes(this.checkboxTargets);
    const checked = checkboxes.some((checkbox) => checkbox.checked);

    if (this.shouldToggleSubmitButton()) {
      const { disabledByDateRange, disabledBySelection } =
        this.submitButtonTarget.dataset;
      this.updateSubmitButtonState(
        checked,
        disabledByDateRange,
        disabledBySelection
      );
    }
  }

  shouldToggleSubmitButton() {
    return this.hasSubmitButtonTarget && this.hasAlertMessageTarget;
  }

  updateSubmitButtonState(checked, disabledByDateRange, disabledBySelection) {
    if (
      this.shouldUpdateSubmitButton(
        checked,
        disabledByDateRange,
        disabledBySelection
      )
    ) {
      this.updateSubmitButton(checked, disabledByDateRange);
    }
  }

  shouldUpdateSubmitButton(checked, disabledByDateRange, disabledBySelection) {
    return (
      !disabledByDateRange ||
      disabledByDateRange === 'false' ||
      !checked ||
      disabledBySelection === disabledByDateRange
    );
  }

  updateSubmitButton(checked, disabledByDateRange) {
    if (disabledByDateRange !== 'true') {
      this.submitButtonTarget.disabled = !checked;
    }
    this.submitButtonTarget.dataset.disabledBySelection = !checked;
    toggleAlertMessage(!checked, this.alertMessageTarget);
  }
}