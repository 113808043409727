import {Controller} from '@hotwired/stimulus'
import {showV2, hideV2} from '../utils/display'

export default class extends Controller {
  static targets = [
    'dropzone',
    'establishmentBlueprint',
    'formBlueprint',
    'blueprint',
    'toggle',
    'input'
  ]

  connect() {
    if (this.hasFormBlueprintTarget && this.hasBlueprintTarget) {
      this.blueprintTarget.disabled = true
      this.inputTarget.disabled = true
    }
  }

  toggleBlueprint({target}) {
    this.inputTarget.value = ''
    this.blueprintTarget.disabled = false
    this.inputTarget.disabled = false

    if (target.checked) {
      this.blueprintTarget.value = 'get_existing_blueprint'
      showV2(this.establishmentBlueprintTarget)
      hideV2(this.dropzoneTarget)
    } else {
      this.blueprintTarget.value = ''
      hideV2(this.establishmentBlueprintTarget)
      showV2(this.dropzoneTarget)
    }

    if (this.hasFormBlueprintTarget) {hideV2(this.formBlueprintTarget)}
  }

  cancelExistingBlueprint() {
    this.toggleTarget.checked = false
  }
}