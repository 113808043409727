import { Controller } from "@hotwired/stimulus"
import { hide, isHidden } from "../utils/display"

export default class extends Controller {
  static targets = ['slug', 'nameInput']
  static values = { autoClose: Number, default: 0 }

  connect() {
    if (this.hasSlugTarget && this.slugTarget.dataset.url && this.slugTarget.dataset.formAction === 'create') {
      window.location.href = this.slugTarget.dataset.url
    }

    if (this.hasNameInputTarget) {
      this.nameInputTarget.focus()
    }

    if (this.autoCloseValue) {
      setTimeout(() => this.close(), this.autoCloseValue * 1000)
    }
  }

  close() {
    hide([this.element])
  }

  disconnect() {
    hide([this.element])
  }

  submitEnd(event) {
    if (this.hasSlugTarget) {
      const action = this.slugTarget.dataset.formAction
      if (event.detail.success && ['edit', 'update'].includes(action)) {
        hide([this.element])
      }
    }
  }
}
