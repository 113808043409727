import { Controller } from "@hotwired/stimulus"
import {
  hideV2,
  showV2
 } from "../utils/display"

export default class extends Controller {
  static targets = [
    'nameField',
    'template',
    'container',
    'error',
    'roomToEvaluate',
    'roomMeasurable',
    'addButton',
    'roomToEvaluateAutodiag',
    'row'
  ]

  connect() {
    if (this.hasRoomToEvaluateTarget) {this.roomToEvaluate = this.roomToEvaluateTarget}
    if (this.hasRoomToEvaluateAutodiagTarget) {this.roomToEvaluate = this.roomToEvaluateAutodiagTarget}
    const emptyNameFields = this.nameFieldTargets.filter(field => !field.hasAttribute('value'))
    if (emptyNameFields.length) {emptyNameFields.forEach(field => field.setAttribute('value', `Salle ${this.nameFieldTargets.indexOf(field) + 1}`))}
    this.createdRoomsNb = this.rowTargets.length
    if (this.hasErrorTarget) {this.fetchErrorMessage()}
    this.lastInputIndex = 0
    if (this.nameFieldTargets.length) {this.lastInputIndex = parseInt(/\d+/g.exec(this.nameFieldTargets[this.nameFieldTargets.length - 1].name)[0])}
  }

  add({currentTarget}) {
    if (this.addable(currentTarget)) {
      const newRoomRow = this.templateTarget.content.cloneNode(true).firstElementChild
      newRoomRow.querySelector('[data-reglementary-room-target="nameField"]').setAttribute('value', `Salle ${this.createdRoomsNb + 1}`)
      const newRule = newRoomRow.outerHTML.replace(/TEMPLATE_INDEX/g, this.lastInputIndex + 1)
      const wrapper = document.createElement('div')
      wrapper.innerHTML = newRule
      this.containerTarget.insertAdjacentHTML('beforeend', wrapper.innerHTML)
      this.addButtonTarget.parentNode.appendChild(this.addButtonTarget)
      this.createdRoomsNb += 1
      this.lastInputIndex += 1
    }
    if (this.hasRoomToEvaluateAutodiagTarget) {this.roomToEvaluateAutodiagTarget.value = this.createdRoomsNb}
    this.toggleErrorMessage()
  }

  remove({currentTarget}) {
    const parentNode = currentTarget.parentNode
    parentNode.remove()
    this.nameFieldTargets.forEach((field, index) => field.value = field.value.replace(/Salle \d+$/g, `Salle ${index + 1}`))
    
    this.createdRoomsNb -= 1
    if (this.hasRoomToEvaluateAutodiagTarget) {this.roomToEvaluateAutodiagTarget.value = this.createdRoomsNb}
    this.toggleErrorMessage()
  }

  toggleErrorMessage() {
    if (!this.hasErrorTarget) {return}
    if (this.errorCorrected()) {
      hideV2(this.errorTarget)
      document.querySelector('.room-measurable-container')?.classList.remove('error')
      document.querySelector('.room-to-evaluate-container')?.classList.remove('error')
    } else {
      showV2(this.errorTarget)
      this.fetchErrorMessage()
    }
  }

  fetchErrorMessage() {
    if (this.roomToEvaluate.value > this.createdRoomsNb) {
      const difference = parseInt(this.roomToEvaluate.value) - this.createdRoomsNb
      const dynamicText = this.dynamicText(difference)
      this.errorTarget.querySelector('p').innerHTML = `Vous avez renseigné un nombre de salles inférieur au minimum légal à évaluer. Merci d’ajouter ${dynamicText} ou de réajuster le nombre de pièces évaluables.`
      document.querySelector('.room-measurable-container')?.classList.add('error')
      document.querySelector('.room-to-evaluate-container').classList.add('error')
    } else if (this.hasRoomMeasurableTarget && this.roomMeasurableTarget.value < this.createdRoomsNb) {
      const difference = this.createdRoomsNb - parseInt(this.roomMeasurableTarget.value)
      const dynamicText = this.dynamicText(difference)
      this.errorTarget.querySelector('p').innerHTML = `Le nombre de salles renseignées dépasse celui des salles évaluables. Merci de supprimer ${dynamicText} ou de réajuster le nombre de pièces évaluables.`
      document.querySelector('.room-measurable-container').classList.add('error')
    }
  }

  dynamicText(difference) {
    if (difference > 1) {
      return `${difference} salles`
    } else {return `${difference} salle`}
  }

  addable(currentTarget) {
    return currentTarget.type === 'button' ||
    ((!this.hasErrorTarget || this.errorTarget.classList.contains('is-hidden')) && 
    currentTarget.classList.contains('room_measurable') && 
    document.querySelector("input[name='form_establishment[nb_rooms_to_evaluate]']").value > this.createdRoomsNb)
  }

  errorCorrected() {
    if (this.hasRoomMeasurableTarget) {
      return parseInt(this.roomToEvaluate.value) <= this.createdRoomsNb && parseInt(this.roomMeasurableTarget.value) >= this.createdRoomsNb
    } else {
      return parseInt(this.roomToEvaluate.value) <= this.createdRoomsNb}
  }
}