export const hide = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.add("is-hidden"));
};

export const show = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.remove("is-hidden"));
};

export const isHidden = (element) => {
  return element.classList.contains("is-hidden") || element.type == 'hidden';
};

export const hideV2 = (...elements) => {
    elements
    .filter((element) => element)
    .forEach((element) => element.classList.add("is-hidden"));
};

export const showV2 = (...elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.remove("is-hidden"));
};

export const isHiddenV2 = (element) => {
  return element.classList.contains("is-hidden") || element.type == 'hidden';
};

export const open = (...elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.add("is-open"));
};

export const close = (...elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.remove("is-open"));
};

export const isOpened = (element) => {
  return element.classList.contains("is-open");
}

export const toggleOpen = (element) => isOpened(element) ? close(element) : open(element)

export const activate = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.add("is-active"))
}

export const desactivate = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.remove("is-active"))
}

export const disabling = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.add("is-disabled"))
}

export const enabling = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => element.classList.remove("is-disabled"))
}

export const isActivated = (element) => {
  return element.classList.contains("is-active")
}

export const current = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => {
      element.classList.add("is-current")
      element.disabled = true
    })
}

export const notCurrent = (elements) => {
  elements
    .filter((element) => element)
    .forEach((element) => {
      element.classList.remove("is-current")
      element.disabled = false
    })
}