import { Controller } from "@hotwired/stimulus"
import { open, close, hideV2, showV2 } from "../utils/display";

const SIDEBAR_IS_SMALLER = 'sidebar_is_smaller'

export default class extends Controller {
  static targets = [
    'sidebar',
    'sidePanel',
    'toggleSidePanel',
    'blueprintsSrc',
    'modal',
    'modalOverlay',
    'sidebarWrapper',
    'content'
  ];

  connect() {
    if (this.hasSidebarTarget) {
      const smallMenu = document.cookie.split(';').filter(e => e.includes('sidebar_is_smaller'))[0] || false
      this.isSmaller = smallMenu && smallMenu.split('=')[1] === 'true'

      this.updateResize(window.innerWidth);
      this.boundOnResize = this.onResize.bind(this)
      window.addEventListener("resize", this.boundOnResize)
    }
  }

  disconnect() {
    document.removeEventListener("keyup", this.boundKeyUpEvent)
    window.removeEventListener("resize", this.boundOnResize)
  }

  toggleSidebar(e) {
    this.sidebarTarget.classList.toggle('is-open');
    e.currentTarget.classList.toggle('l-header__burger-button--active');
  }

  closeSidePanel(){
    close(this.sidePanelTarget)
    this.sidePanelTarget.dispatchEvent(new Event('closeSidepanel'))
  }

  openSidePanel(){
    open(this.sidePanelTarget)
  }

  toggleSmallerSidebar() {
    this.isSmaller = !this.isSmaller
    document.cookie = `${SIDEBAR_IS_SMALLER}=${this.isSmaller}; SameSite=None; Secure; path=/ `
    this.setSmallerSidebar(this.isSmaller)
  }

  setSmallerSidebar(value) {
    if (value) {
      this.sidebarTarget.classList.add('is-smaller');
      this.contentTarget.classList.add('is-smaller-padding');
    } else {
      this.sidebarTarget.classList.remove('is-smaller');
      this.contentTarget.classList.remove('is-smaller-padding');
    }

    //Pour communiquer avec l'autre controller "more_badge"
    const isSmallerChangeEvent = new CustomEvent('isSmallerChange', { detail: this.isSmaller });
    window.dispatchEvent(isSmallerChangeEvent);
  }

  updateResize(width) {
    this.setSmallerSidebar(width > 768 && this.isSmaller);
  }

  onResize(event){
    this.updateResize(event.target.innerWidth);
  }

  nextModal () {
    const modalPages = Array.from(this.modalTarget.querySelectorAll('.modal-page'));

    const index = modalPages.findIndex(modalPage => {
      return !modalPage.classList.contains('is-hidden');
    })

    hideV2(modalPages[index]);

    const nextModalPage = modalPages[index + 1];
    if (!nextModalPage) {
      // TODO finish form
      return this.closeModal();
    }

    // next modal exists
    showV2(nextModalPage)
  }

  openModal () {
    open(this.modalTarget)
    open(this.modalOverlayTarget)

  }

  closeModal (event) {
    close(this.modalTarget)
    close(this.modalOverlayTarget)
  }
}
