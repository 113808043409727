import { Controller } from "@hotwired/stimulus"
import { open, close, isOpened } from "../utils/display"

export default class extends Controller {
  static targets = ['panel', 'linkTrigger', 'svgTrigger']

  toggle() {
    isOpened(this.panelTarget) ? close(this.panelTarget): open(this.panelTarget)
  }

  disableLink() {
    this.linkTriggerTarget.classList.add('is-hidden')
    this.svgTriggerTarget.classList.remove('is-hidden')
  }
}