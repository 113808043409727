import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['template', 'container', 'measureType', 'condition']

  connect() {
    if (this.hasMeasureTypeTarget) {
      const selectedValue = Array.from(this.measureTypeTarget.getElementsByTagName('option')).filter(option => option.selected)[0].value
      let selectedLi
      if (selectedValue) {
        selectedLi = Array.from(this.measureTypeTarget.getElementsByTagName('li')).filter(li => li.dataset['customSelectValueParam'] == selectedValue)[0]
      } else {
        selectedLi = this.measureTypeTarget.getElementsByTagName('li')[0]
      }
      const { stepValue } = selectedLi.dataset
      this.element.querySelector('input[type=number]').step = stepValue
    }
  }

  add() {
    const newRule = this.templateTarget.innerHTML.replace(/TEMPLATE_INDEX/g, new Date().valueOf())
    const wrapper = document.createElement('div')
    wrapper.innerHTML = newRule
    const { stepValue } = wrapper.querySelector('div[data-alert-rules-target="measureType"]').getElementsByTagName('li')[0].dataset
    wrapper.querySelector('input[type=number]').step = stepValue
    this.containerTarget.insertAdjacentHTML('beforeend', wrapper.innerHTML)
  }

  remove(event) {
    const parentNode = event.currentTarget.parentNode
    if (parentNode?.nextElementSibling?.tagName == 'INPUT' && parentNode?.nextElementSibling?.type == 'hidden') {
      parentNode.nextElementSibling.remove()
    }
    parentNode.remove()
  }

  updateStep({currentTarget, detail}) {
    const ruleValueInput = currentTarget.querySelector('input[type=number]')
    ruleValueInput.value = ''
    ruleValueInput.step = detail.stepValue
  }
}