import { Controller } from "@hotwired/stimulus"

const DEFAULT_TIME_ITERVAL = 'hour'

export default class extends Controller {

	static targets = [
		'offlineDisable',
		'dayToggle',
		'offlineToggle',
		'timeInterval',
		'timeFrame'
	]


	connect() {
		if (this.hasOfflineDisableTarget && this.offlineToggleTarget.checked) {
			this.offlineDisableTarget.classList.add('is-desactivated')
		}

		if (this.hasDayToggleTarget && !this.dayToggleTarget.checked) {
			this.timeFrameTarget.classList.add('is-desactivated')
		}
	}

	offline({ target }) {
		this.toggle(this.offlineDisableTarget, !target.checked)
		
		if (target.checked) { this.updateTimeInterval() }
	}

	changed({ target }) {
		this.toggle(this.timeFrameTarget, target.checked)
	}

	toggle(element, value) {
		value ? element.classList.remove('is-desactivated') : element.classList.add('is-desactivated')
	}

	updateTimeInterval() {
		const select = this.timeIntervalTarget.getElementsByTagName('select')[0]
		const selectedOption = this.timeIntervalTarget.getElementsByClassName('dropdown-option-select__selected')[0]
		select.value = DEFAULT_TIME_ITERVAL
		selectedOption.classList.remove('placeholder')
		selectedOption.innerHTML = select.selectedOptions[0].label
	}
}