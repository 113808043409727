import { Controller } from '@hotwired/stimulus'
import { initDateRangePicker } from '../utils/flatpickr'

export default class extends Controller {
	static targets = ['dateInput']

	connect() {
    if (this.hasDateInputTarget) {
      const options = {
        mode: 'single',
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        onChange: (selectedDates, dateStr, instance) => {
          this.dispatch('change', { detail: { value: selectedDates }})
        }
      }

      this.datePicker = initDateRangePicker(this.dateInputTarget, options)
		}
  }
}
